import { Injectable } from '@angular/core';
import { Organization } from '../../state/models/organization';
import { AmplitudeService } from './amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  organization: Organization | undefined;
  setByApi: boolean;

  constructor(private amplitudeService: AmplitudeService) {
  }

  get hasSetByApi() {
    return this.setByApi;
  }

  setOrganization(organization?: Organization): boolean {
    if (organization) {
      const ho = this.getOrgByType(organization, organization?.parent, 'HEALTH_OPERATOR');
      const rh = this.getOrgByType(organization, organization?.parent, 'HUMAN_RESOURCES');
      if (ho) {
        this.amplitudeService.addIdentifyEvent('ho_id', ho?.id);
        this.amplitudeService.addIdentifyEvent('ho_name', ho?.name);
      }

      if (rh) {
        this.amplitudeService.addIdentifyEvent('rh_id', rh?.id);
        this.amplitudeService.addIdentifyEvent('rh_name', rh?.name);
      }

      this.organization = organization;
      this.setByApi = true;
    } else {
      this.setByApi = false;
      const marOrgWhitelabel = document.querySelector('meta[property="marOrgWhitelabel"]');
      const content = marOrgWhitelabel?.getAttribute('content');
      this.organization = content !== '{marOrgWhitelabel}' ? JSON.parse(decodeURIComponent(content)) : DEFAULT_ORGANIZATION;
    }
    return true;
  }

  getOrganization(): Organization {
    return this.organization;
  }


  getOrgByType(organization: Organization, parent: Organization, type: Organization['type']) {
    if (organization.type === type) {
      return organization;
    }
    if (parent?.type === type) {
      return parent;
    }
    return undefined;
  }

}

const DEFAULT_ORGANIZATION = {
  nameUrlSafe: 'mar',
  name: 'Mar Saúde',
  logo: 'https://mar-static.s3.us-east-2.amazonaws.com/organization-logo/mar.png',
  favicon: 'https://mar-static.s3.us-east-2.amazonaws.com/organization-favicon/mar.ico'
};
